import { SITE_DOMAIN, SITE_NAME } from "../constants"
import { SOCIAL_MEDIA } from "../constants/social-media"
/*
Organization:
    - Similar to LocalBusiness
    - Don't aggregate reviews from other websites.
      - https://developers.google.com/search/docs/advanced/structured-data/review-snippet#technical-guidelines
      - https://whitespark.ca/blog/how-to-use-aggregate-review-schema-to-get-stars-in-the-serps/
    - Schema.org: https://schema.org/Organization
*/
export const organizationDetails = {
  name: SITE_NAME,
  addressDetails: {
    streetAddress: "1550 5 St SW Suite 300",
    addressLocality: "Calgary",
    addressRegion: "AB",
    postalCode: "T2R 1K3",
    addressCountry: "Canada",
  },
  url: `${SITE_DOMAIN}/`,
  openingHours: ["Mo-Fr 00:00-23:59"],
  telephone: "1-855-909-3105",
  email: "hello@10adventures.com",
  legalName: "10Adventures.com Inc",
  keywords: [
    "adventure tours",
    "hiking tours",
    "trekking tours",
    "hiking app",
    "gps navigation",
    "backpacking",
    "backcountry",
    "adventure travel",
  ],
  description:
    "10 Adventures is the best place to plan your next adventure.  We have free daytrip route guides, a GPS app, and an online marketplace for purchasing personalized adventure travel from incredible local tour operators in more than 75 countries.",
  slogan: "We are your Adventure HQ",
  logo: `${SITE_DOMAIN}/assets/10Adventures-logo-2-light.png`,
  socialMedia: SOCIAL_MEDIA,
}
const generateOrganization = data => {
  const { addressDetails, socialMedia, ...structuredData } = organizationDetails
  structuredData["@context"] = "https://schema.org"
  structuredData["@type"] = "Organization"
  structuredData["address"] = { "@type": "PostalAddress", ...addressDetails }
  // Generate sameAs links
  const sameAs = []
  for (const socialMediaPlatform in socialMedia) {
    sameAs.push(socialMedia[socialMediaPlatform].link)
  }
  structuredData["sameAs"] = sameAs
  if (data && data.image) {
    structuredData["image"] = data.image
  }
  return structuredData
}

export default generateOrganization
