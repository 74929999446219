import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"

const IconLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  & > span {
    text-decoration: underline;
  }
`
const IconTitle = styled.h3`
  margin: 6px 8px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: 10px 0;
  }
`
const ContentIcon = styled.div`
  margin: 0 5px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (${props => props.theme.screen.small.min}) {
    flex: 1 1 ${props => (props.count ? 100.0 / props.count : 0)}%;
  }
`
const ContentIconList = styled.div`
  display: flex;
  text-align: center;

  ${props =>
    props.variant === "mobile-vertical" &&
    css`
      flex-direction: column;
      & ${ContentIcon} {
        margin: 0 0 20px;
      }

      @media only screen and (${props => props.theme.screen.small.min}) {
        flex-direction: row;
        & ${ContentIcon} {
          margin: 0 0 20px;
        }
      }
    `}
`
const ContentIconBar = ({ className, icons, variant }) => {
  const IconList =
    icons &&
    icons.map((icon, index) => {
      const IconItem = (
        <>
          {icon.icon && <Icon glyph={icon.icon} />}
          {icon.title && <IconTitle>{icon.title}</IconTitle>}
          {icon.text && <span>{icon.text}</span>}
        </>
      )
      return (
        <ContentIcon
          count={icons && icons.length}
          key={`${index}-${icon.link}`}
          variant={variant}
        >
          {icon.link ? (
            <IconLink to={icon.link}>{IconItem}</IconLink>
          ) : (
            <>{IconItem}</>
          )}
        </ContentIcon>
      )
    })

  return IconList && IconList.length ? (
    <ContentIconList className={className} variant={variant}>
      {IconList}
    </ContentIconList>
  ) : undefined
}
ContentIconBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["blue", "white", "mobile-vertical"]),
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      link: PropTypes.string,
    })
  ),
}
export default ContentIconBar
