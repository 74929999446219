import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { window, exists } from "browser-monads"
import theme from "@src/styles/theme"
import TableOfContents from "@src/components/core-table-of-contents"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"
const StyledTableOfContentsTitle = styled.h3``
const StyledTableOfContentsFooterLink = styled(Link)`
  text-decoration: none;
  & > .icon {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
  & > .title {
    display: block;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    padding-top: 6px;
  }
  &.next {
    text-align: right;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`
const StyledTableOfContentsFooter = styled.div`
  background-color: ${props => props.theme.lightBlue};
  & > ${StyledTableOfContentsFooterLink} {
    display: block;
  }
`
const StyledTableOfContents = styled(TableOfContents)`
  margin: -20px 0 20px;

  & ul {
    justify-content: flex-start;
  }

  &:not(.has-entries),
  ${StyledTableOfContentsTitle}, ${StyledTableOfContentsFooter} {
    display: none;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: -50px 0 25px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    margin: 0;

    &:not(.has-entries),
    ${StyledTableOfContentsTitle}, ${StyledTableOfContentsFooter} {
      display: block;
    }

    ${StyledTableOfContentsFooter} {
      margin: 0 -14px -15px;
      padding: 15px 16px 16px;
    }
  }
`
const TableOfContentsBlogPost = ({
  title,
  entries,
  navigation: { prevPost, nextPost },
}) => {
  const scrollOffset =
    exists(window) && window.innerWidth < theme.screen.small.minWidth
      ? -305
      : -100
  return (
    <StyledTableOfContents
      header={
        title && entries && entries.length ? (
          <StyledTableOfContentsTitle>{title}</StyledTableOfContentsTitle>
        ) : undefined
      }
      entries={
        entries &&
        entries.map(item => ({
          title: item.title,
          target:
            item.target.substr(0, 1) === "#"
              ? item.target.substr(1)
              : item.target,
        }))
      }
      footer={
        prevPost || nextPost ? (
          <StyledTableOfContentsFooter>
            {prevPost && (
              <StyledTableOfContentsFooterLink
                variant="blue"
                className="prev"
                to={prevPost.uri}
                title={prevPost.title}
              >
                <Icon glyph="circle-chevron-left" />{" "}
                <strong>Previous Blog</strong>
                <span className="title">{prevPost.title}</span>
              </StyledTableOfContentsFooterLink>
            )}
            {nextPost && (
              <StyledTableOfContentsFooterLink
                variant="blue"
                className="next"
                to={nextPost.uri}
                title={nextPost.title}
              >
                <strong>Next Blog</strong> <Icon glyph="circle-chevron-right" />
                <span className="title">{nextPost.title}</span>
              </StyledTableOfContentsFooterLink>
            )}
          </StyledTableOfContentsFooter>
        ) : undefined
      }
      scrollOffset={scrollOffset}
    />
  )
}
TableOfContentsBlogPost.propTypes = {
  title: PropTypes.string,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  navigation: PropTypes.shape({
    prevPost: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    nextPost: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
}
export default TableOfContentsBlogPost
