import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import HeaderBlog from "@src/components/page-header-blog-post"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import ResponsiveSection from "@src/components/container-responsive-section"
import TableOfContentsBlogPost from "@src/components/content-toc-blog-post"
import IconContentBar from "@src/components/core-icon-bar"
import { ImagePropTypes } from "@src/components/core-image"
import { ICON_BAR_TOURS } from "@src/utils/constants/icon-bar-tours"
import HtmlContent from "@src/components/core-value-html"
import { SITE_DOMAIN } from "@src/utils/constants"
import generateBlogPosting from "@src/utils/structured-data/generateBlogPosting"
import NewsletterSubscriptionForm from "@src/components/form-newsletter-subscription"

const StyledNewsletterSubscriptionForm = styled(NewsletterSubscriptionForm)`
  @media only screen and (${props => props.theme.screen.small.max}) {
    display: none;
  }
`

const filterBlogPostContent = content =>
  content.replaceAll(/<h2 class="sub-header/g, '<h2 class="h4 sub-header')

const PageTemplate = ({ data }) => {
  const {
    post: {
      title,
      uri,
      date,
      author: { node: author },
      category,
      tableOfContents,
      content: sourceContent,
      images: { header },
      seo: pageSeo,
    },
    prevPost,
    nextPost,
  } = data
  const content = filterBlogPostContent(sourceContent)
  GTM.dataLayerPushPageInfo({
    template: "blog-post",
  })
  const rawStructuredData = {
    title,
    author,
    time: date,
    canonicalUrl: `${SITE_DOMAIN}${uri}`,
    // eslint-disable-next-line
    image: header?.src,
  }
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.opengraphTitle || pageSeo.title)}
        meta={generateMeta(uri, {
          ...pageSeo,
          metaRobotsNoindex: "index",
        })}
        structuredData={generateBlogPosting(rawStructuredData)}
      />
      <HeaderBlog
        image={header}
        title={title}
        author={author}
        date={Date.parse(`${date}Z`)}
        categories={[category]}
      />
      {(tableOfContents && tableOfContents.length) || prevPost || nextPost ? (
        <ResponsiveColumns>
          <div>
            <TableOfContentsBlogPost
              title="Quick Navigation"
              entries={
                tableOfContents &&
                tableOfContents.map(item => ({
                  title: item.title,
                  target:
                    item.target.substr(0, 1) === "#"
                      ? item.target.substr(1)
                      : item.target,
                }))
              }
              navigation={{ prevPost, nextPost }}
            />
            <StyledNewsletterSubscriptionForm
              variant="widget"
              checkboxLabel="I agree to subscribe to the mailing list"
            />
          </div>
          <HtmlContent
            html={content.replaceAll("<h4>", "<h4 class='sub-header'>")}
            fancyHeaders
            imageBlocks
            captionBlocks
            disclosures
          />
        </ResponsiveColumns>
      ) : (
        <ResponsiveSection>
          <HtmlContent
            html={content.replaceAll("<h4>", "<h4 class='sub-header'>")}
            fancyHeaders
            imageBlocks
            captionBlocks
            disclosures
          />
        </ResponsiveSection>
      )}
      <ResponsiveSection variant="blue" visible="small">
        <IconContentBar icons={ICON_BAR_TOURS} />
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
      date: PropTypes.string,
      author: PropTypes.shape({
        node: PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        }),
      }),
      category: PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      }),
      tableOfContents: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          target: PropTypes.string,
        })
      ),
      excerpt: PropTypes.string,
      content: PropTypes.string,
      images: PropTypes.shape({
        header: PropTypes.shape({ ...ImagePropTypes }),
      }),
      seo: PropTypes.shape({ ...PageSeoPropTypes }),
    }),
    prevPost: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    nextPost: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
}
export default PageTemplate

export const query = graphql`
  query ($postId: String!, $prevId: String!, $nextId: String!) {
    post: wpPost(id: { eq: $postId }) {
      title
      uri
      date: dateGmt
      author {
        node {
          name
          link: uri
        }
      }
      category {
        name
        link: uri
      }
      tableOfContents {
        title
        target
      }
      excerpt
      content
      images {
        header: feature {
          ...WpImageSizesQuery
        }
      }
      seo {
        ...WpPostSeoQuery
      }
    }
    prevPost: wpPost(id: { eq: $prevId }) {
      title
      uri
    }
    nextPost: wpPost(id: { eq: $nextId }) {
      title
      uri
    }
  }
`
