import React, { useState, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { window, exists } from "browser-monads"

export const StyledPanel = styled.div`
  position: sticky;
  z-index: ${props => props.theme.layers.floatingUI};
  background-color: ${props => props.theme.bg1};
  overflow: hidden;
  top: ${props => props.theme.header.tablet.height}px;
  @media only screen and (${props => props.theme.header.small.min}) {
    top: ${props => props.theme.header.small.height}px;
  }
  @media only screen and (${props => props.theme.header.medium.min}) {
    top: ${props => props.theme.header.medium.height}px;
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    top: ${props => props.theme.header.large.height}px;
  }
`

const StickyCollapsePanel = ({
  className,
  children,
  minScroll,
  hideAfter,
  showAfter,
  forceCollapse,
  locked,
}) => {
  const [collapsed, setCollapsed] = useState(forceCollapse)

  const scroll = useRef({
    last: exists(window) ? window.scrollY : 0,
    track: 0,
    oldHeight: null,
    skipEvent: false,
    locked: false,
  })
  // skip first event after a rerender since collapse/uncollapse throw off the offset
  scroll.current.skipEvent = true

  useEffect(() => {
    setCollapsed(forceCollapse)
    scroll.current.track = 0
    scroll.current.skipEvent = true
  }, [forceCollapse])

  useEffect(() => {
    scroll.current.locked = locked
  }, [locked])

  const handleWindowScroll = useCallback(() => {
    if (!exists(window)) {
      return
    }
    const heightDiff =
      scroll.current.oldHeight &&
      scroll.current.oldHeight !== window.document.documentElement.offsetHeight
        ? window.document.documentElement.offsetHeight -
          scroll.current.oldHeight
        : 0

    const currentScroll = window.scrollY
    const scrollDiff =
      scroll.current.locked || scroll.current.skipEvent
        ? 0
        : currentScroll - scroll.current.last
    if (
      (scrollDiff < 0 && scroll.current.track > 0) ||
      (scrollDiff > 0 && scroll.current.track < 0)
    ) {
      scroll.current.track = 0
    }
    scroll.current.track += scrollDiff
    scroll.current.last = currentScroll

    if (scroll.current.skipEvent) {
      scroll.current.skipEvent = false
    }

    if (scroll.current.locked) {
      return
    }

    if (
      scroll.current.track <= showAfter ||
      currentScroll < minScroll + heightDiff
    ) {
      scroll.current.oldHeight = null
      setCollapsed(false)
    } else if (
      scroll.current.track >= hideAfter &&
      currentScroll >= minScroll + heightDiff
    ) {
      if (!scroll.current.oldHeight) {
        scroll.current.oldHeight = window.document.documentElement.offsetHeight
      }
      setCollapsed(true)
    }
  }, [setCollapsed, hideAfter, minScroll, showAfter])

  useEffect(() => {
    if (exists(window)) {
      window.addEventListener("scroll", handleWindowScroll)
      return () => window.removeEventListener("scroll", handleWindowScroll)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <StyledPanel className={className} collapsed={collapsed}>
      {React.Children.toArray(children).map(item =>
        React.cloneElement(item, { collapsed })
      )}
    </StyledPanel>
  )
}
StickyCollapsePanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  minScroll: PropTypes.number,
  hideAfter: PropTypes.number,
  showAfter: PropTypes.number,
  forceCollapse: PropTypes.bool,
  locked: PropTypes.bool,
}
StickyCollapsePanel.defaultProps = {
  minScroll: 250,
  hideAfter: 50,
  showAfter: -250,
}
export default StickyCollapsePanel
