export const ICON_BAR_TOURS = [
  {
    icon: "star",
    text: "10 Reasons to Book with Us",
    link: "/about-10adventures-tours/",
  },
  {
    icon: "smiled",
    text: "Happiness Guarantee",
    link: "/happiness-guarantee/",
  },
  {
    icon: "bar-chart",
    text: "Trips for All Levels",
    link: "/tour-difficulty-assessment/",
  },
  {
    icon: "hiker",
    text: "Experienced Local Guides",
    link: "/guides/",
  },
]
