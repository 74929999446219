import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Link as ReactScrollLink,
  Events as ReactScrollEvents,
} from "react-scroll"
import { StyledLink, FancyLink } from "@src/components/core-link"

const StyledScrollLink = styled(ReactScrollLink)`
  ${StyledLink}
  cursor: pointer;
  &.active {
    ${props => props.$variant === "fancy" && FancyLink}
    color: ${props => props.$variant !== "fancy" && props.theme.primary}
  }
`

const ScrollLink = ({
  className,
  children,
  variant,
  href,
  active,
  scroll,
  ...props
}) => {
  const handleScrollStart = useCallback(
    (to, element) => {
      if (!scroll || !scroll.onStart) {
        return
      }
      scroll.onStart(to, element)
    },
    [scroll]
  )
  const handleScrollFinish = useCallback(
    (to, element) => {
      if (!scroll || !scroll.onFinish) {
        return
      }
      scroll.onFinish(to, element)
    },
    [scroll]
  )
  const handleScrollSetActive = useCallback(
    to => {
      if (!scroll || !scroll.onSetActive) {
        return
      }
      scroll.onSetActive(to)
    },
    [scroll]
  )
  const handleScrollSetInactive = useCallback(
    target => {
      if (!scroll || !scroll.onSetInactive) {
        return
      }
      scroll.onSetInactive(target)
    },
    [scroll]
  )

  useEffect(() => {
    if (!scroll) {
      return
    }
    ReactScrollEvents.scrollEvent.register("begin", handleScrollStart)
    ReactScrollEvents.scrollEvent.register("end", handleScrollFinish)
    return () => {
      ReactScrollEvents.scrollEvent.remove("begin", handleScrollStart)
      ReactScrollEvents.scrollEvent.remove("end", handleScrollFinish)
    }
  }, [scroll, handleScrollStart, handleScrollFinish])

  if (active) {
    if (!className) {
      className = ""
    } else {
      className += " "
    }
    className += "active"
  }

  return (
    <StyledScrollLink
      smooth={true}
      href={href}
      to={scroll.to}
      onSetActive={handleScrollSetActive}
      onSetInactive={handleScrollSetInactive}
      className={className}
      activeClass="active"
      spy={true}
      offset={scroll.offset || scroll.offset === 0 ? scroll.offset : -200}
      $variant={variant}
      {...props}
    >
      {children}
    </StyledScrollLink>
  )
}
ScrollLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["default", "blue", "fancy", "compact", "simple"]),
  href: PropTypes.string,
  active: PropTypes.bool,
  scroll: PropTypes.shape({
    to: PropTypes.string,
    offset: PropTypes.number,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
    onSetActive: PropTypes.func,
    onSetInactive: PropTypes.func,
  }),
}
export default ScrollLink
