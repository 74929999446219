import generateOrganization from "./generateOrganization"
/*
BlogPosting:
  - Google: https://developers.google.com/search/docs/advanced/structured-data/article
  - Schema.org: https://schema.org/BlogPosting
*/
const generateBlogPosting = data => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    author: {
      "@type": "Person",
      name: data.author.name,
    },
    datePublished: data.time,
    headline: data.title,
    publisher: {
      ...generateOrganization(),
    },
    mainEntityOfPage: data.canonicalUrl,
  }
  if (data.image) {
    structuredData["image"] = data.image
  }
  return structuredData
}

export default generateBlogPosting
