import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import HeaderSimple from "@src/components/page-header-simple"
import Image, { ImagePropTypes } from "@src/components/core-image"
import Link from "@src/components/core-link"
import FormatDate from "@src/components/core-value-date"

const StyledHeader = styled(HeaderSimple)`
  display: block;
  padding: 150px 0 0;
  min-height: unset;
  background-color: ${props => props.theme.bg1};
  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 390px 0 0;
  }
`
const HeaderContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.bg1};
  text-align: center;
  border-radius: 10px 10px 0 0;
  max-width: 100%;
  margin: 0 10px;
  padding: 14px 30px;
  & > h1 {
    margin: 0 0 14px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    max-width: 1098px;
    padding: 30px 80px;
    border-radius: 40px 40px 0 0;
    & > h1 {
      margin: 0 0 24px;
    }
  }
  @media only screen and (min-width: ${1098 + 20}px) {
    margin: 0 auto;
  }
`
const HeaderImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 480px;
  }
`
const PostDetailStyle = css`
  display: inline-block;
  &:not(.author) {
    margin-left: 10px;
  }
`
const PostDetail = styled.span`
  ${PostDetailStyle};
`
const CategoryLink = styled(Link)`
  ${PostDetailStyle};
  padding: 2px 8px;
  border-radius: 2px;
  background-color: ${props => props.theme.primary};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.fg1};
  }
`

const HeaderBlog = ({ image, title, author, categories, date }) => (
  <StyledHeader spacer="shadow">
    {image && (
      <HeaderImage
        artDirection={{ mobile: "100vw", small: "100vw" }}
        lazyload={false}
        {...image}
      />
    )}
    <HeaderContent>
      <h1>{title}</h1>
      {author && author.name && (
        <PostDetail className="author">{`By ${author.name}`}</PostDetail>
      )}

      {date && (
        <PostDetail className="post-date">
          <FormatDate date={date} />
        </PostDetail>
      )}
      {categories && categories.length && (
        <CategoryLink to={categories[0].link} className="category">
          {categories[0].name}
        </CategoryLink>
      )}
    </HeaderContent>
  </StyledHeader>
)
HeaderBlog.propTypes = {
  title: PropTypes.string,
  date: PropTypes.number,
  author: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  image: PropTypes.shape({ ...ImagePropTypes }),
}

export default HeaderBlog
